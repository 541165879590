<template>
  <v-card>
    <v-card-text>
      <v-row dense class="pb-2" justify="center">
        <v-col cols="11">
          <v-text-field
            label="Search"
            append-icon="fas fa-search"
            single-line
            hide-details
            v-model="search"
            clearable
            color="color3"
            solo-inverted
          >
          </v-text-field>
        </v-col>
        <v-col cols="1" class="text-end">
          <v-btn
            color="color3"
            text icon
            @click.stop="drawer = true"
          >
            <v-icon>fas fa-ellipsis-v</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :items="registrations"
        :headers="headers"
        :loading="loading"
        :options.sync="page"
        :search="search"
        class="elevation-1 mb-2"
        :footer-props="{'items-per-page-options': [25, 50, 100, -1]}"
        @current-items="getFiltered"
        ref="regTable"
      >
        <template v-slot:[`item.amount`]="{ item }">
          {{ item.amount | usDollars}} <i class="fad fa-user-shield" v-if="item.quickAdd"></i>
        </template>
        <template v-slot:[`item.pending`]="{ item }">
          {{ item.pending | usDollars}}
        </template>
        <template v-slot:[`item.amountRefunded`]="{ item }">
          <span class="error--text">{{ item.amountRefunded | usDollars }}</span>
        </template>
        <template v-slot:[`item.externalAmount`]="{ item }">
          <span>{{ item.externalAmount | usDollars }}</span>
        </template>
        <template v-slot:[`item.unix`]="{ item }">
          {{ item.unix | unixToLocalDt }}
        </template>
        <template v-slot:[`item.dtLastWithdraw`]="{ item }">
          {{ item.dtLastWithdraw | unixToLocalDt }}
        </template>
        <template v-slot:[`item.team.tags`]="{ item }">
          <v-chip v-for="(tag, i) in item.team.tags" :key="`${tag}_${i}`" small>{{tag}}</v-chip>
        </template>
        <template v-slot:[`item.teamName`]="{ item }">
          <span :class="{deleted: item.isDeleted}">{{ item.teamName }}</span>
        </template>
        <template v-slot:[`item.playerNames`]="{ item }">
          <template v-if="showMemberId && item.team">
            <div
              v-for="p in item.team.players"
              :key="p.id"
              :class="{deleted: item.isDeleted}"
            >{{p.fullname}} {{p.getMemberId(theme.username)}}</div>
          </template>
          <span v-else :class="{deleted: item.isDeleted}">{{ item.playerNames }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
                text
                small
                :to="{name: 'division-team', params: {teamId: item.teamId}}"
              >
                manage
                <v-icon color="color3" class="ml-3">fas fa-caret-right</v-icon>
              </v-btn>
        </template>
      </v-data-table>
      <v-row dense justify="center" align="center">
      </v-row>
      <v-row dense>
        <v-col class="text-right" cols="12" >
          <team-message-button
            :tournament="tournament"
            :teams="notifyItems"
          ></team-message-button>
          <pending-payments-manager
            :pendingItems="pendingItems"
            :tournament="tournament"
            @updated="getRegistrations"
            @email-sent="onEmailSent"
          ></pending-payments-manager>
          <v-btn
            color="color3"
            v-if="false"
            text
            small
            @click.stop="$emit('upload-click')"
          >
            <v-icon class="mr-4">fas fa-file-import</v-icon>
            Import
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-navigation-drawer
      right
      v-model="drawer"
      fixed temporary
      :width="$vuetify.breakpoint.xs ? 350 : 500"
    >
      <v-card flat>
        <v-card-title>
          Filters
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-switch hide-details class="shrink mr-3" color="success" label="Hide Withdrawn" dense v-model="hideWithdrawn"></v-switch>
          <v-switch hide-details class="shrink mr-3" color="success" label="Withdrawn Only" dense v-model="withdrawnOnly"></v-switch>
          <v-switch hide-details class="shrink mr-3" color="success" label="Hide Waitlist" dense v-model="hideWaitlist"></v-switch>
          <v-switch hide-details class="shrink mr-3" color="success" label="Waitlist Only" dense v-model="waitlistOnly"></v-switch>
          <v-switch hide-details class="shrink mr-3" color="success" label="Show Pending Payments" dense v-model="pendingPayments"></v-switch>
          <v-switch hide-details class="shrink mr-3" color="success" label="Incomplete Only" dense v-model="incomplete"></v-switch>
          <v-switch hide-details class="shrink mr-3" color="success" label="Single Player Only" dense v-model="singles" v-if="tournament.organization.id === 80"></v-switch>
          <v-switch hide-details class="shrink mr-3" color="success" label="Show Duplicate Players" dense v-model="dupPlayers"></v-switch>
          <v-switch hide-details class="shrink mr-3" color="success" label="Show Team Tags" dense v-model="teamTags"></v-switch>
        </v-card-text>
        <v-card-title>
          Actions
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="pa-3">
            <v-btn color="color3 color3Text--text" class="mr-2" @click="getRegistrations" text x-small>
              <v-icon small class="mr-3">fas fa-sync-alt</v-icon>Refresh Registrations
            </v-btn>
          </div>
          <div class="pa-3">
            <export-dialog :textBtn="true" :xSmall="true"></export-dialog>
          </div>
          <div class="pa-3">
            <financial-download></financial-download>
          </div>
          <div class="pa-3">
            <summary-dialog :tournament="tournament" :loading="loading" :data="data"></summary-dialog>
          </div>
        </v-card-text>
      </v-card>

    </v-navigation-drawer>
  </v-card>
</template>

<script>
import moment from 'moment'
import ExportDialog from '../../Tournament/ExportDialog.vue'
import SummaryDialog from './SummaryDialog.vue'
import FinancialDownload from './FinancialDownload'
import { mapGetters } from 'vuex'
import flatten from '@/helpers/ArrayFlatten'
import TeamMessageButton from '@/components/Notifications/TeamMessageButtonNew'
const PendingPaymentsManager = () => import('@/components/Tournament/Registration/PendingPaymentsManager')

export default {
  props: ['tournament', 'active'],
  data () {
    return {
      loading: false,
      summary: false,
      data: [],
      page: { itemsPerPage: 25 },
      search: null,
      exporting: false,
      hideWithdrawn: false,
      hideWaitlist: false,
      incomplete: false,
      dupPlayers: false,
      teamTags: false,
      notifyItems: [],
      singles: false,
      drawer: false,
      withdrawnOnly: false,
      waitlistOnly: false,
      pendingPayments: false
    }
  },
  computed: {
    ...mapGetters(['getTeam', 'theme', 'user']),
    headers () {
      return [
        {
          text: 'Id',
          align: 'left',
          sortable: true,
          value: 'id',
          show: this.user && this.user.vbl
        },
        {
          text: 'Tags',
          align: 'left',
          sortable: true,
          value: 'team.tags',
          show: this.teamTags
        },
        {
          text: 'Division',
          align: 'left',
          sortable: true,
          value: 'division',
          show: true
        },
        {
          text: 'Team',
          align: 'left',
          sortable: true,
          value: 'teamName',
          show: true
        },
        { text: 'Players', value: 'playerNames', show: true },
        { text: 'Field', value: 'field', show: this.data.filter(f => f.field !== 'NA').length > 0 },
        {
          text: this.withdrawnOnly ? 'Date Withdrew' : 'Date Registered',
          align: 'left',
          sortable: true,
          value: this.withdrawnOnly ? 'dtLastWithdraw' : 'unix',
          show: true
        },
        {
          text: '$ Paid',
          align: 'left',
          sortable: true,
          value: 'amount',
          show: true
        },
        {
          text: '$ Pending',
          align: 'left',
          sortable: true,
          value: 'pending',
          show: this.data && !!this.data.find(f => ['Pending', 'Payment Requested'].includes(f.paymentStatus))
        },
        {
          text: '$ Refunded',
          align: 'left',
          sortable: true,
          value: 'amountRefunded',
          show: true
        },
        {
          text: '$ Paid Externally',
          align: 'left',
          sortable: true,
          value: 'externalAmount',
          show: this.data && !!this.data.find(f => f.externalAmount)
        },
        {
          text: 'Promo',
          align: 'left',
          sortable: true,
          value: 'promo',
          show: true
        },
        {
          value: 'actions',
          sortable: false,
          show: true
        }
      ].filter(f => f.show)
    },
    showMemberId () {
      return this.theme.membership || ['aau', 'avp', 'usav', 'p1440', 'gnbv', 'bvne'].includes(this.theme.username)
    },
    pendingItems () {
      return this.data.filter(f => !!f.pending && !f.isDeleted && !f.waitlist && !f.externalAmount)
    },
    registrations () {
      let base = this.hideWithdrawn ? this.data.filter(f => !f.isDeleted) : this.data
      if (this.withdrawnOnly) {
        base = base.filter(f => f.isDeleted)
      }
      if (this.hideWaitlist) {
        base = base.filter(f => !f.waitlist)
      }
      if (this.waitlistOnly) {
        base = base.filter(f => f.waitlist)
      }
      if (this.pendingPayments) {
        base = base.filter(f => !!f.pending && !f.isDeleted && !f.waitlist)
      }
      if (this.incomplete) {
        base = base.filter(f => f.dObj && f.team && f.team.players.length < f.dObj.numOfPlayers)
      }
      if (this.singles) {
        base = base.filter(f => f.dObj && f.team && f.team.players.length < 2)
      }
      if (this.dupPlayers) {
        base = base.filter(f => {
          return this.playerCounts.includes(f.team.id)
        })
      }

      return base
    },
    playerCounts () {
      const result = []
      this.data.filter(f => !f.isDeleted && f.team).forEach(d => {
        d.team.players.forEach(p => {
          const a = result.find(f => f.id === p.playerProfileId)
          if (!a) {
            result.push({ id: p.playerProfileId, teams: [d.team.id], divisions: [d.divisionId] })
          } else {
            a.teams.push(d.team.id)
            if (!a.divisions.includes(d.divisionId)) a.divisions.push(d.divisionId)
          }
        })
      })

      return flatten(result.filter(f => f.teams.length > 1).map(m => m.teams))
    },
    displayItems () {
      return this.$refs.regTable && this.$refs.regTable.internalCurrentItems
    },
    notificationCount () {
      return this.displayItems ? this.displayItems.length : this.registrations.length
    }
  },
  methods: {
    getRegistrations () {
      this.loading = true
      this.$VBL.tournament.getRawRegistrationData(this.tournament.id)
        .then((response) => {
          this.data = response.data
          this.data.forEach((d) => {
            d.dtRegisteredF = moment(d.dtRegistered).format('L LT')
            d.unix = +moment(d.dtRegistered)
            const x = ['Pending', 'Payment Requested'].includes(d.paymentStatus)
            console.log('here')
            d.pending = x ? (+d.amount - d.externalAmount) : 0
            d.amount = x ? 0 + d.externalAmount : d.amount
            // d.quickAdd = !!d.teamNotes.find(f => f.text === 'Admin QuickAdd')
            const div = this.tournament.divisions.find(f => f.id === d.divisionId)
            if (div) d.dObj = div
            d.team = this.getTeam(d.teamId)
          })
          this.loading = false
        })
        .catch((error) => {
          console.log(error.response)
          this.loading = false
        })
    },
    getWithdrawDates () {
      this.loading = true
      this.$VBL.tournament.getWithdrawDates(this.tournament.id)
        .then(r => {
          r.data.forEach(wd => {
            var data = this.data.find(f => f.teamId === wd.id)
            if (data) {
              data.dtLastWithdraw = +moment(wd.dtLastWithdraw)
            }
          })
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    onEmailClick () {
      this.$emit('email-click', this.notifyItems.map(m => m.teamId))
    },
    getFiltered (e) {
      this.notifyItems = e
    },
    onEmailSent () {
      this.$emit('email-sent')
    }
  },
  watch: {
    active: function (val) {
      if (val) {
        this.getRegistrations()
      }
    },
    withdrawnOnly: function (v) {
      if (v) {
        this.getWithdrawDates()
      }
    }
  },
  mounted () {
    this.active && this.getRegistrations()
  },
  components: {
    ExportDialog,
    SummaryDialog,
    FinancialDownload,
    TeamMessageButton,
    PendingPaymentsManager
  }
}
</script>

<style scoped>
  .deleted {
    text-decoration: line-through
  }
  >>>.v-data-table .v-data-table__mobile-row {
    min-height: 24px !important;
  }
</style>
